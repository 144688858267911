import ApiCalls from "./ApiCalls";
import rules from "@/pages/common/rules.js";
export default {
    mixins: [rules, ApiCalls],
    data() {
        return {

        }
    },
    methods: {



    },
}